
var editarUsuario = angular.module('usuariosAdmin');

editarUsuario.controller('usuarioAdminController', ['$scope', '$routeParams', '$location', 'usuariosAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $routeParams, $location, usuariosAdminService, authenticationInfo, authenticationService) {

    $scope.authentication = authenticationInfo;

    usuariosAdminService.getUsuario($routeParams.id)
            .then(function (response) {
                var data = response.data;
                $scope.usuario = data;
            });


        usuariosAdminService.getRoles()
            .then(function (response) {
                var data = response.data;
                $scope.roles = data;
            });

        usuariosAdminService.getRolesPorUsuario($routeParams.id)
            .then(function (response) {
                var data = response.data;
                $scope.rolUsuario = data;
            });


    $scope.gestionUsuarios = function () {
        $location.path('/admin/usuarios');
    }
        $scope.editarUsuario = function () {
            usuariosAdminService.put($scope.usuario, $scope.rolUsuario).then(function (response) {
                var result = response.data;
                alert(result);
                $location.path("/admin/usuarios");

            }).catch(function (response) {
                $scope.error = usuariosAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }


    }]);