angular.module('login', [])
    .controller('loginController', ['$scope', '$rootScope', 'authenticationInfo', 'authenticationService', '$location', function ($scope, $rootScope, authenticationInfo, authenticationService, $location) {
        $scope.authentication = authenticationInfo;
        $scope.authentication.isAuthenticated = false;
        $scope.usuario = authenticationInfo.userName;
        $scope.dobleAutentication = "Código";

        $scope.login = function () {
            //var aux = $location.path();
            //$location.path('/infraestructuras');
            //aux = $location.path();
            //$scope.$apply();
            authenticationService.activado2FA($scope.usuario)
                .then(function (response) {
                    $scope.activado = response.data;
                    if ($scope.activado == true) {
                        authenticationService.verificar2FA($scope.dobleAutentication, $scope.usuario)
                            .then(function (response) {
                                $scope.FA2 = response.data;

                                if ($scope.FA2 == false) {
                                    alert("clave incorrecta");
                                }
                                else {
                                    return authenticationService.login({
                                        userName: $scope.usuario,
                                        password: $scope.password,
                                    }).then(function () {
                                        //$scope.authentication = authenticationInfo;
                                        if (authenticationInfo.isAuthenticated == true && $scope.FA2 == true) {
                                            $scope.authentication.isAuthenticated = true;
                                            $rootScope.usuario = $scope.usuario;
                                        }
                                        else {
                                            alert("Usuario o Contraseña incorrecta");
                                        }
                                    });
                                }
                            });
                    }
                    else {
                        alert("Para mayor seguridad, sería recomendable activar la autenticación de dos factores");
                        return authenticationService.login({
                            userName: $scope.usuario,
                            password: $scope.password,
                        }).then(function () {
                            //$scope.authentication = authenticationInfo;
                            if (authenticationInfo.isAuthenticated == true) {
                                $scope.authentication.isAuthenticated = true;
                                $rootScope.usuario = $scope.usuario;
                            }
                            else {
                                alert("Usuario o Contraseña incorrecta");
                            }
                        });
                    }
                });

        }
    }]);