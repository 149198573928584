var infraestructura = angular.module('infraestructuras');

infraestructura.controller('infraestructuraController', ['$rootScope', '$scope', '$location', 'infraestructurasService', '$routeParams', 'authenticationInfo', 'authenticationService', 'infraestructurasAdminService'
    , function ($rootScope, $scope, $location, infraestructurasService, $routeParams, authenticationInfo, authenticationService, infraestructurasAdminService) {

        $scope.authentication = authenticationInfo;
        $scope.pestanyavisible = 'general';
        $scope.pestanyavisibleInsp = 'general';

    if ($rootScope.parteUrl == true) {
        $scope.OpcionSeleccionada = "app/Partes/partes.html";
        $scope.OpcionSeleccionadaClass = 'parte';
        $scope.pestanyaparte = "tab-parte active"
        $scope.pestanyanube = "tab-nube"
    }
    else {
        $scope.OpcionSeleccionada = "app/TV/TV.html";
        $scope.OpcionSeleccionadaClass = 'tv';
        $scope.pestanyaparte = "tab-parte"
        $scope.pestanyanube = "tab-nube active"
    }

    $scope.idObra = $routeParams.id;

        infraestructurasAdminService.getEntornos()
            .then(function (response) {
                var data = response.data;
                $scope.entornos = data;
            });


        infraestructurasAdminService.getPropietarios()
            .then(function (response) {
                var data = response.data;
                $scope.propietarios = data;
            });

        infraestructurasAdminService.getMunicipios()
            .then(function (response) {
                var data = response.data;
                $scope.municipios = data;
            });


    infraestructurasService.getInfraestructura($routeParams.id)
        .then(function (response) {
            var data = response.data;
            $scope.infraestructura = data;

     
            if ($scope.infraestructura.IndiceInspeccion.Id == 1)
                $scope.infraestructura.IndiceInspeccionInfraestructura = "infraestructura-box infraestructura-status-0 row";
            else if ($scope.infraestructura.IndiceInspeccion.Id == 2)
                $scope.infraestructura.IndiceInspeccionInfraestructura = "infraestructura-box infraestructura-status-1 row";
            else if ($scope.infraestructura.IndiceInspeccion.Id == 3)
                $scope.infraestructuras.IndiceInspeccionInfraestructura = "infraestructura-box infraestructura-status-2 row";

        });

        infraestructurasService.getTunel($routeParams.id)
            .then(function (response) {
                var data = response.data;
                $scope.tunel = data;
            });

        infraestructurasService.getInspeccion($routeParams.id)
            .then(function (response) {
                var data = response.data;
                $scope.inspeccion = data;
            });

    infraestructurasService.getTiposTunel()
            .then(function (response) {
                var data = response.data;
                $scope.tiposTunel = data;
            });

        infraestructurasService.getObstaculos()
            .then(function (response) {
                var data = response.data;
                $scope.obstaculos = data;
            });

        infraestructurasService.getNumDireccionesTunel()
            .then(function (response) {
                var data = response.data;
                $scope.numDireccionesTunel = data;
            });

        infraestructurasService.getLongitudTunel()
            .then(function (response) {
                var data = response.data;
                $scope.longitudes = data;
            });

        infraestructurasService.getMaterialesTunel()
            .then(function (response) {
                var data = response.data;
                $scope.materiales = data;
            });

        infraestructurasService.getTipoVia()
            .then(function (response) {
                var data = response.data;
                $scope.tiposVia = data;
            });

        infraestructurasService.getCirculacionPermitida()
            .then(function (response) {
                var data = response.data;
                $scope.circulacionesPermitida = data;
            });

    infraestructurasService.getCategoria($rootScope.categoria)
        .then(function (response) {
            var data = response.data;
            $scope.categoria = data;
        });


    $scope.verCategorias = function () {
        $location.path('/categorias');
        };

        $scope.guardarTunel = function () {
            $scope.tunel.InfraestructuraId = $routeParams.id;
            $scope.tunel.AñoConstruccion = $scope.tunel.AnioConstruccion;
            $scope.tunel.MaterialHastialDcholId = $scope.tunel.MaterialHastialDchoId;
            if ($scope.tunel.Id != undefined) {
                infraestructurasService.guardarTunel($scope.tunel, $scope.infraestructura.CodIdentificador, $scope.infraestructura.Propietario.Id, $scope.infraestructura.Entorno.Id, $scope.infraestructura.Municipio.Id)
                    .then(function (response) {
                        var data = response.data;
                        $scope.tunel.Id = data;
                    });
                alert("Datos guardados correctamente");
            } else {
                $scope.tunel.Id = -1;
                infraestructurasService.guardarTunel($scope.tunel)
                    .then(function (response) {
                                var data = response.data;
                                $scope.tunel.Id = data;
                    });
                alert("Datos guardados correctamente");
            }
        };


        $scope.volver = function () {
            $location.path('/categorias');
        }

    $scope.getModelo = function () {
        //$location.path("/modelo/" + $routeParams.id);
        $scope.OpcionSeleccionada = "app/VisorModelos/visorModelos.html";
        $scope.OpcionSeleccionadaClass = 'modelos';
    }


    $scope.getSensores = function () {
        //$location.path("/modelo/" + $routeParams.id);
        $scope.OpcionSeleccionada = "app/Sensores/Sensores.html";
        $scope.OpcionSeleccionadaClass = 'sensores';
    }


    $scope.getNubeDePuntos = function () {
        //$location.path("/TV/" + $routeParams.id);
        $scope.OpcionSeleccionada = "app/TV/TV.html";
        $scope.OpcionSeleccionadaClass = 'tv';
    }

    $scope.getGestionDocumental = function () {
        //$location.path("/TV/" + $routeParams.id);
        $scope.OpcionSeleccionada = "app/GestionDocumental/gestiondocumental.html";
        $scope.OpcionSeleccionadaClass = 'docs';
    }

    $scope.getRealidadVirtual = function () {
        //$location.path("/TV/" + $routeParams.id);
        $scope.OpcionSeleccionada = "app/TourVirtual/tourvirtual.html";
        $scope.OpcionSeleccionadaClass = 'rv';
    }


    $scope.getPartes = function () {
        //$location.path("/TV/" + $routeParams.id);
        $scope.OpcionSeleccionada = "app/Partes/partes.html";
        $scope.OpcionSeleccionadaClass = 'parte';
    }


    $scope.logout = function () {
        //$rootScope.bodylayout = "login";
        return authenticationService.logout();
    }

        $scope.TipologiaCargar = function () {
            $scope.pestanyavisible = 'tipologia';
        }

        $scope.DimensionesCargar = function () {
            $scope.pestanyavisible = 'dimensiones';
        }

        $scope.MaterialesCargar = function () {
            $scope.pestanyavisible = 'materiales';
        }

        $scope.ImportanciaCargar = function () {
            $scope.pestanyavisible = 'importancia';
        }
        $scope.ElementosInspeccionadosCargar = function () {
            $scope.pestanyavisibleInsp == 'elementosinspeccionados';
        }   
        $scope.ElementosEvaluadosCargar = function () {
            pestanyavisibleInsp == 'elementosevaluados'
        }
}]);