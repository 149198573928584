var app = angular
    .module('galibos', [])
    .factory('galibosService', ['$http', '$q', '__env', '$routeParams', function galibosService($http, $q, __env, $routeParams) {
        var serviceUrl = __env.apiUrl;
        var galibosUrl = 'galibos';
        var adjuntogalibosUrl = 'adjuntogalibos';
        var graficosUrl = 'graficos';


        function getDatosParametrizables(usuario) {
            //return $http.get(serviceUrl + galibosUrl + '/' + usuario + '/');
            return $http({
                method: 'get',
                url: serviceUrl + '/' + adjuntogalibosUrl + '/',
                params:
                {
                    'usuario': usuario
                }

            });

        }


        function getInfraestructurasCategoria(id, usuario) {
            return $http({
                method: 'get',
                url: serviceUrl + 'infraestructuras/categoria/' + id,
                params:
                {
                    'usuario': usuario
                }

            });
        }


        function postDatosParametrizables(idUsuario) {
            return $http.post(serviceUrl + adjuntogalibosUrl + '/post/' + usuario + '/');
        }

        function getDesplazamientosLateralesVerticales(contorno, contornoInf, TN, Rv, M3bAlto, M3bBajo, M3hAlto, M3hBajo, kh5, TVia, Kgen,
            estadoBalasto, ac, asusp, TD, I0, I, D,
            D0, L, R, lmax, hc0) {

            //return $http.get(serviceUrl + galibosUrl + '/lateralesVerticales/');

            return $http({
                method: 'get',
                url: serviceUrl + galibosUrl + '/lateralesVerticales/',
                params:
                {
                    'Contorno': contorno,
                    'ContornoInf': contornoInf,
                    'TN': TN,
                    'Rv': Rv,
                    'M3bAlto': M3bAlto,
                    'M3bBajo': M3bBajo,
                    'M3hAlto': M3hAlto,
                    'M3hBajo': M3hBajo,
                    'kh5': kh5,
                    'TVia': TVia,
                    'Kgen': Kgen,
                    'Ac': ac,
                    'EstadoBalasto': estadoBalasto,
                    'Asusp': asusp,
                    'TD': TD,
                    'I0': I0,
                    'D': D,
                    'I': I,
                    'D0': D0,
                    'L': L,
                    'R': R,
                    'lmax': lmax,
                    'hc0': hc0
                }

            });
        }

        function getGaliboAnden(tension, tipoAnden, bordeInt, bordeExt, Tq, TVia, TD, Kgen, I, I0, L, D, D0, R, lmax, estadoBalasto,
            ac, asusp, hc0, b, AnchoVia, Hcrmin, Hnez) {

            //return $http.get(serviceUrl + galibosUrl + '/badenes/');

            return $http({
                method: 'get',
                url: serviceUrl + galibosUrl + '/badenes/',
                params:
                {
                    'tension': tension,
                    'tipoAnden': tipoAnden,
                    'bordeInt': bordeInt,
                    'bordeExt': bordeExt,
                    'Tq': Tq,
                    'TVia': TVia,
                    'TD': TD,
                    'Kgen': Kgen,
                    'I': I,
                    'I0': I0,
                    'L': L,
                    'D': D,
                    'D0': D0,
                    'R': R,
                    'lmax': lmax,
                    'Ac': ac,
                    'Asusp': asusp,
                    'hc0': hc0,
                    'b': b,
                    'EstadoBalasto': estadoBalasto,
                    'AnchoVia': AnchoVia,
                    'Hcrmin': Hcrmin,
                    'Hnez': Hnez
                }

            });
        }

        function getDesplazamientosNominal(tension, contorno, contornoInf, TN, Rv, M3bAlto, M3bBajo, M3hAlto, M3hBajo, kh5, TVia, Kgen,
            EstadoBalasto, ac, asusp, TD, I0, I, D,
            D0, L, R, lmax, hc0, Pantografo, Catenaria, pant_cw, pant_hf, pant_ho, pant_hu, pant_epo, pant_epu, pant_IO, pant_Do) {

            //return $http.get(serviceUrl + galibosUrl + '/nominal/');

            return $http({
                method: 'get',
                url: serviceUrl + galibosUrl + '/nominal/',
                params:
                {
                    'tension': tension,
                    'Contorno': contorno,
                    'ContornoInf': contornoInf,
                    'TN': TN,
                    'Rv': Rv,
                    'M3bAlto': M3bAlto,
                    'M3bBajo': M3bBajo,
                    'M3hAlto': M3hAlto,
                    'M3hBajo': M3hBajo,
                    'kh5': kh5,
                    'TVia': TVia,
                    'Kgen': Kgen,
                    'EstadoBalasto': EstadoBalasto,
                    'Ac': ac,
                    'Asusp': asusp,
                    'TD': TD,
                    'I': I,
                    'I0': I0,
                    'D': D,
                    'D0': D0,
                    'L': L,
                    'R': R,
                    'lmax': lmax,
                    'hc0': hc0,
                    'Pantografo': Pantografo,
                    'Catenaria': Catenaria,
                    'pant_cw': pant_cw,
                    'pant_hf': pant_hf,
                    'pant_ho': pant_ho,
                    'pant_hu': pant_hu,
                    'pant_epo': pant_epo,
                    'pant_epu': pant_epu,
                    'pant_IO': pant_IO,
                    'pant_Do': pant_Do
                }

            });
        }


        function getContornos() {
            return $http.get(serviceUrl + adjuntogalibosUrl + '/contornos/');
        }

        function getContornosInferiores() {
            return $http.get(serviceUrl + adjuntogalibosUrl + '/contornosinferiores/');
        }

        function getAnchosVia() {
            return $http.get(serviceUrl + adjuntogalibosUrl + '/anchosvia/');
        }

        function getPantografo() {
            return $http.get(serviceUrl + adjuntogalibosUrl + '/pantografo/');
        }

        function getTension() {
            return $http.get(serviceUrl + adjuntogalibosUrl + '/tension/');
        }

        function getEstadoBalasto() {
            return $http.get(serviceUrl + adjuntogalibosUrl + '/descripcionestadosbalasto/');
        }

        function getTipoAnden() {
            return $http.get(serviceUrl + adjuntogalibosUrl + '/tiposanden/');
        }

        function getCatenarias() {
            return $http.get(serviceUrl + adjuntogalibosUrl + '/catenarias/');
        }


        function getDatosEstadoBalasto(estado) {
            // return $http.get(serviceUrl + adjuntogalibosUrl + '/datosestadosbalasto/' + estado);
            return $http({
                method: 'get',
                url: serviceUrl + adjuntogalibosUrl + '/datosestadosbalasto/',
                params:
                {
                    'estado': estado
                }
            });

        }

        function getRol(usuario, infraestructura) {
            return $http({
                method: 'get',
                url: serviceUrl + adjuntogalibosUrl + '/rol/',
                params:
                {
                    'usuario': usuario,
                    'infraestructura': infraestructura
                }
            });
        }

        //function imprimir(datosEntradaCalculoGalibos, datosParametrizablesCalculoGalibos, galiboVerticalHorizontal, galiboNominal, galiboAnden) {
        function imprimir(datosEntradaCalculoGalibos, datosParametrizablesCalculoGalibos, formDataTexto) {
            return $http({
                method: 'post',
                url: serviceUrl + adjuntogalibosUrl + '/adjunto/',
                responseType: 'blob',
                headers: { 'Content-Type': undefined },
                params:
                {
                    'datosEntradaCalculoGalibos': JSON.stringify(datosEntradaCalculoGalibos),
                    'datosParametrizablesCalculoGalibos': JSON.stringify(datosParametrizablesCalculoGalibos),
                    //'galiboVerticalHorizontal': JSON.stringify(galiboVerticalHorizontal),
                    //'galiboNominal': JSON.stringify(galiboNominal),
                    //'galiboAnden': JSON.stringify(galiboAnden),
                },
                data: formDataTexto,
            });
        }

        function dibujarGraficos(formDataTexto) {
            return $http({
                method: 'post',
                url: serviceUrl + graficosUrl + '/dibujar/',
                responseType: 'blob',
                headers: { 'Content-Type': undefined },
                data: formDataTexto,
            });
        }

        return {
            getContornos: getContornos,
            getContornosInferiores: getContornosInferiores,
            getAnchosVia: getAnchosVia,
            getDatosParametrizables: getDatosParametrizables,
            postDatosParametrizables: postDatosParametrizables,
            getRol: getRol,
            getGaliboAnden: getGaliboAnden,
            getDesplazamientosLateralesVerticales: getDesplazamientosLateralesVerticales,
            getDesplazamientosNominal: getDesplazamientosNominal,
            imprimir: imprimir,
            getPantografo: getPantografo,
            getTension: getTension,
            getEstadoBalasto: getEstadoBalasto,
            dibujarGraficos: dibujarGraficos,
            getDatosEstadoBalasto: getDatosEstadoBalasto,
            getTipoAnden: getTipoAnden,
            getCatenarias: getCatenarias
        }
    }]);