var nuevoUsuario = angular.module('usuariosAdmin');

nuevoUsuario.controller('nuevoUsuarioAdminController', ['$scope', '$routeParams', '$location', 'usuariosAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $routeParams, $location, usuariosAdminService, authenticationInfo, authenticationService) {
    $scope.error = '';
    $scope.authentication = authenticationInfo;

        $scope.roles = usuariosAdminService.getUsuarios()
            .then(function (response) {
                var data = response.data;
                $scope.usuarios = data;
            });

        usuariosAdminService.getRoles()
            .then(function (response) {
                var data = response.data;
                $scope.roles = data;
            });
    $scope.gestionUsuarios = function () {
        $location.path('/admin/usuarios');
    }

        $scope.guardarUsuario = function () {

            usuariosAdminService.post($scope.user, $scope.rolUsuario).then(function (response) {
                var result = response.data;
                alert(result);
                $location.path("/admin/usuarios");
                //$location.path("/Admin");
            }).catch(function (response) {
                $scope.error = usuariosAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }
    }]);