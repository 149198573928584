
var editarUsuarioPropio = angular.module('usuariosAdmin');

editarUsuarioPropio.controller('usuarioPropioAdminController', ['$scope', '$routeParams', '$location', 'usuariosAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $routeParams, $location, usuariosAdminService, authenticationInfo, authenticationService) {

    $scope.authentication = authenticationInfo;
    $scope.usuarioNombre = $routeParams.usuario;
    $scope.OcultoF2A = false;

    usuariosAdminService.getUsuarioByName($routeParams.usuario)
        .then(function (response) {
            var data = response.data;
            $scope.usuario = data;
        });

    $scope.volver = function () {
        $location.path('/categorias');
    }

    usuariosAdminService.getRoles()
        .then(function (response) {
            var data = response.data;
            $scope.roles = data;
        });

    usuariosAdminService.getRolesPorUsuario($routeParams.id)
        .then(function (response) {
            var data = response.data;
            $scope.rolUsuario = data;
        });

    $scope.editarUsuario = function () {
        $scope.usuario.userName = $scope.usuarioNombre
        usuariosAdminService.putPropio($scope.usuario).then(function (response) {
            var result = response.data;
            alert(result);
            //$location.path("/admin/usuarios");

        }).catch(function (response) {
            $scope.error = usuariosAdminService.obtenerPrimerErrorModelState(response.data);
            alert($scope.error);
        });
    }

    $scope.dobleAutentication = function () {
        usuariosAdminService.dobleAutentication($scope.usuarioNombre)
            .then(function (response) {
                $scope.OcultoF2A = true;
                $scope.QR = response.data;
            });
    }

    //$scope.activar2FA = function () {
    //    var codigo = prompt('Introduce el codigo 2FA:', '2FA');
    //    usuariosAdminService.activar2FA(codigo, $scope.usuarioNombre)
    //        .then(function (response) {
    //            $scope.QR = response.data;

    //        });
    //}


}]);