//const { forEach } = require("lodash");

var app = angular.module('galibos');

app.controller('galibosController', ['$rootScope', '$scope', '$location', '$route', '$routeParams', 'galibosService', 'infraestructurasService', 'authenticationInfo', 'authenticationService',
    function ($rootScope, $scope, $location, $route, $routeParams, galibosService, infraestructurasService, authenticationInfo, authenticationService) {

        let myChart;
        let myChart2;

        $scope.authentication = authenticationInfo;
        $scope.rol = authenticationInfo.rol;
        $scope.usuario = $rootScope.usuario;
        $scope.descargaActiva = true;

        $scope.pestanyavisible = 'datosentrada';

        //VARIABLES PARA EL CONTORNO CINEMATICO DE REFERENCIA

        //var CCRefh1 = 0.4;
        //var CCRefh2 = 1.15;
        //var CCRefh3 = 1.15;
        //var CCRefh4 = 3.32;
        //var CCRefh5 = 3.32;
        //var CCRefh6 = 3.32;
        //var CCRefh7 = 4.545;


        //var CCRefb1 = 1.695;
        //var CCRefb2 = 1.695;
        //var CCRefb3 = 1.72;
        //var CCRefb4 = 1.72;
        //var CCRefb5 = 1.72;
        //var CCRefb6 = 1.72;
        //var CCRefb7 = 1.49;

        var b = 1.695;
        $scope.b = b;
        $scope.k = 0;
        $scope.S_0 = 0.4;




        galibosService.getEstadoBalasto()
            .then(function (response) {
                $scope.estadosbalasto = response.data;
            });

        galibosService.getContornos()
            .then(function (response) {
                var data = response.data;
                $scope.contornos = data;
            });

        galibosService.getTipoAnden()
            .then(function (response) {
                var data = response.data;
                $scope.tiposAndenes = data;
            });

        galibosService.getCatenarias()
            .then(function (response) {
                var data = response.data;
                $scope.catenarias = data;
            });

        $scope.imprimir = function () {

            var elemento = document.getElementById('loader-seguimiento');
            elemento.style.display = 'block';

            galibosService.getDesplazamientosLateralesVerticales($scope.Contorno.Id, $scope.ContornoInferior.Id, $scope.TN, $scope.Rv,
                $scope.datosparametrizables.M3balto, $scope.datosparametrizables.M3bbajo, $scope.datosparametrizables.M3halto,
                $scope.datosparametrizables.M3hbajo, $scope.datosparametrizables.Kh05, $scope.datosparametrizables.Tvia, $scope.datosparametrizables.Kgen,
                $scope.EstadoBalasto.Descripcion, $scope.datosparametrizables.ac, $scope.datosparametrizables.asusp, $scope.TD, $scope.datosparametrizables.I0,
                $scope.I, $scope.D, $scope.datosparametrizables.D0, $scope.datosparametrizables.L, $scope.R, $scope.datosparametrizables.lmax, $scope.datosparametrizables.hc0)            

                .then(function (response) {
                    $scope.galiboVerticalHorizontal = response.data;
                    //alert('Cálculo de desplazamientos laterales verticales completado');

                    galibosService.getGaliboAnden($scope.Tension.Id, $scope.tipoAnden.Id, $scope.bordeInt, $scope.bordeExt, $scope.Tq, $scope.datosparametrizables.Tvia, $scope.TD, $scope.datosparametrizables.Kgen,
                        $scope.I, $scope.datosparametrizables.I0, $scope.datosparametrizables.L, $scope.D, $scope.datosparametrizables.D0, $scope.R, $scope.datosparametrizables.lmax,
                        $scope.EstadoBalasto.Descripcion,
                        $scope.datosparametrizables.ac, $scope.datosparametrizables.asusp, $scope.datosparametrizables.hc0, b, $scope.AnchoVia.Id, $scope.Hcrmin, $scope.Hnez)

                        .then(function (response) {
                            $scope.galiboAnden = response.data;
                            //alert('Cálculo de gálibo andén completado');

                            galibosService.getDesplazamientosNominal($scope.Tension.Id, $scope.Contorno.Id, $scope.ContornoInferior.Id, $scope.TN, $scope.Rv,
                                $scope.datosparametrizables.M3balto, $scope.datosparametrizables.M3bbajo, $scope.datosparametrizables.M3halto,
                                $scope.datosparametrizables.M3hbajo, $scope.datosparametrizables.Kh05, $scope.datosparametrizables.Tvia, $scope.datosparametrizables.Kgen,
                                $scope.EstadoBalasto.Descripcion, $scope.datosparametrizables.ac, $scope.datosparametrizables.asusp, $scope.TD, $scope.datosparametrizables.I0,
                                $scope.I, $scope.D, $scope.datosparametrizables.D0, $scope.datosparametrizables.L, $scope.R, $scope.datosparametrizables.lmax, $scope.datosparametrizables.hc0, $scope.Pantografo.Id,
                                $scope.catenaria.Id, $scope.datosparametrizables.pant_cw, $scope.datosparametrizables.pant_hf, $scope.datosparametrizables.pant_ho, $scope.datosparametrizables.pant_hu,
                                $scope.datosparametrizables.pant_epo, $scope.datosparametrizables.pant_epu, $scope.datosparametrizables.pant_I0, $scope.datosparametrizables.pant_Do)

                                .then(function (response) {
                                    $scope.galiboNominal = response.data;
                                    //alert('Cálculo de desplazamiento nominal completado');

                                    // $scope.imprimir = function () {

                                    var datosentrada = new Object();

                                    datosentrada.ContornoInferior = $scope.ContornoInferior.Id;
                                    datosentrada.AnchoVia = $scope.AnchoVia.Id;
                                    datosentrada.Contorno = $scope.Contorno.Id;
                                    datosentrada.R = $scope.R;
                                    datosentrada.D = $scope.D;
                                    datosentrada.I = $scope.I;
                                    datosentrada.TD = $scope.TD;
                                    datosentrada.TN = $scope.TN;
                                    datosentrada.Rv = $scope.Rv;
                                    datosentrada.EstadoBalasto = $scope.EstadoBalasto.Descripcion;
                                    datosentrada.Pantografo = $scope.Pantografo.Id;
                                    datosentrada.Tension = $scope.Tension.Id;
                                    datosentrada.catenaria = $scope.catenaria.Id;

                                    $scope.datosentrada = datosentrada;

                                    var formDataTexto = new FormData();
                                    formDataTexto.append("galiboVerticalHorizontal", JSON.stringify($scope.galiboVerticalHorizontal));
                                    formDataTexto.append("galiboNominal", JSON.stringify($scope.galiboNominal));
                                    formDataTexto.append("galiboAnden", JSON.stringify($scope.galiboAnden));

                                    //     galibosService.imprimir($scope.datosentrada, $scope.datosparametrizables, $scope.galiboVerticalHorizontal, $scope.galiboNominal, $scope.galiboAnden)

                                    galibosService.imprimir($scope.datosentrada, $scope.datosparametrizables, formDataTexto)
                                        .then(function (data, status, headers) {
                                            var filename = 'Documento Adjunto para Galibos.doc';
                                            var contentType = 'application/msword';

                                            var blob = new Blob([data.data],
                                                { type: contentType });

                                            if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                                                window.navigator.msSaveBlob(blob, filename);
                                            else //Resto
                                            {
                                                var downloadLink = document.createElement("a");
                                                var url = window.URL.createObjectURL(blob);
                                                downloadLink.setAttribute("target", "_blank");
                                                downloadLink.setAttribute("href", url);
                                                downloadLink.setAttribute("download", filename);
                                                document.body.appendChild(downloadLink);
                                                downloadLink.click();
                                                document.body.removeChild(downloadLink);
                                                elemento.style.display = 'none';
                                            }

                                        })



                                });


                        });



                });
        }

        $scope.calcular = function () {

            var elemento = document.getElementById('loader-seguimiento');
            elemento.style.display = 'block';

            galibosService.getDesplazamientosLateralesVerticales($scope.Contorno.Id, $scope.ContornoInferior.Id, $scope.TN, $scope.Rv,
                $scope.datosparametrizables.M3balto, $scope.datosparametrizables.M3bbajo, $scope.datosparametrizables.M3halto,
                $scope.datosparametrizables.M3hbajo, $scope.datosparametrizables.Kh05, $scope.datosparametrizables.Tvia, $scope.datosparametrizables.Kgen,
                $scope.EstadoBalasto.Descripcion, $scope.datosparametrizables.ac, $scope.datosparametrizables.asusp, $scope.TD, $scope.datosparametrizables.I0,
                $scope.I, $scope.D, $scope.datosparametrizables.D0, $scope.datosparametrizables.L, $scope.R, $scope.datosparametrizables.lmax, $scope.datosparametrizables.hc0)

                .then(function (response) {
                    $scope.galiboVerticalHorizontal = response.data;

                    galibosService.getGaliboAnden($scope.Tension.Id, $scope.tipoAnden.Id, $scope.bordeInt, $scope.bordeExt, $scope.Tq, $scope.datosparametrizables.Tvia, $scope.TD, $scope.datosparametrizables.Kgen,
                        $scope.I, $scope.datosparametrizables.I0, $scope.datosparametrizables.L, $scope.D, $scope.datosparametrizables.D0, $scope.R, $scope.datosparametrizables.lmax,
                        $scope.EstadoBalasto.Descripcion,
                        $scope.datosparametrizables.ac, $scope.datosparametrizables.asusp, $scope.datosparametrizables.hc0, b, $scope.AnchoVia.Id, $scope.Hcrmin, $scope.Hnez)

                        .then(function (response) {
                            $scope.galiboAnden = response.data;

                            galibosService.getDesplazamientosNominal($scope.Tension.Id, $scope.Contorno.Id, $scope.ContornoInferior.Id, $scope.TN, $scope.Rv,
                                $scope.datosparametrizables.M3balto, $scope.datosparametrizables.M3bbajo, $scope.datosparametrizables.M3halto,
                                $scope.datosparametrizables.M3hbajo, $scope.datosparametrizables.Kh05, $scope.datosparametrizables.Tvia, $scope.datosparametrizables.Kgen,
                                $scope.EstadoBalasto.Descripcion, $scope.datosparametrizables.ac, $scope.datosparametrizables.asusp, $scope.TD, $scope.datosparametrizables.I0,
                                $scope.I, $scope.D, $scope.datosparametrizables.D0, $scope.datosparametrizables.L, $scope.R, $scope.datosparametrizables.lmax, $scope.datosparametrizables.hc0, $scope.Pantografo.Id,
                                $scope.catenaria.Id, $scope.datosparametrizables.pant_cw, $scope.datosparametrizables.pant_hf, $scope.datosparametrizables.pant_ho, $scope.datosparametrizables.pant_hu,
                                $scope.datosparametrizables.pant_epo, $scope.datosparametrizables.pant_epu, $scope.datosparametrizables.pant_I0, $scope.datosparametrizables.pant_Do)

                                .then(function (response) {
                                    $scope.galiboNominal = response.data;
                                    //alert('Cálculo de desplazamiento nominal completado')

                                    var formDataTexto = new FormData();
                                    formDataTexto.append("galiboVerticalHorizontal", JSON.stringify($scope.galiboVerticalHorizontal));
                                    formDataTexto.append("galiboNominal", JSON.stringify($scope.galiboNominal));
                                    formDataTexto.append("galiboAnden", JSON.stringify($scope.galiboAnden));


                                    //Dibujo Grafico Limite
                                    if (myChart) {
                                        myChart.destroy();
                                    }

                                    const ctx = document.getElementById('graficoLimite').getContext('2d');
                                    myChart = new Chart(ctx, {
                                        type: 'scatter', // Tipo de gráfico
                                        data: {
                                            datasets: [{
                                                label: 'GaliboEnvolventeInt', // Etiqueta del conjunto de datos
                                                data: [
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeIntB[0], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeIntH[0] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeIntB[1], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeIntH[1] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeIntB[2], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeIntH[2] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeIntB[3], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeIntH[3] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeIntB[4], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeIntH[4] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeIntB[5], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeIntH[5] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeIntB[6], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeIntH[6] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeIntB[7], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeIntH[7] }
                                                ],
                                                borderColor: 'rgba(255, 99, 132, 1)', // Color de la línea Rojo
                                                borderWidth: 2,
                                                showLine: true, // Muestra la línea que une los puntos
                                                fill: false, // Evita que se rellene el área debajo de la línea
                                                //tension: 0.4, // Suavizado de la línea
                                            },
                                            {
                                                label: 'GaliboEnvolventeExt', // Etiqueta del conjunto de datos
                                                data: [
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeExtB[0], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeExtH[0] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeExtB[1], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeExtH[1] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeExtB[2], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeExtH[2] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeExtB[3], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeExtH[3] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeExtB[4], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeExtH[4] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeExtB[5], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeExtH[5] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeExtB[6], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeExtH[6] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeExtB[7], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboEnvolventeExtH[7] }
                                                ],
                                                borderColor: 'rgba(54, 162, 235, 1)', // Color de la línea Azul
                                                borderWidth: 2,
                                                showLine: true, // Muestra la línea que une los puntos
                                                fill: false, // Evita que se rellene el área debajo de la línea
                                                //tension: 0.4, // Suavizado de la línea
                                            },
                                            //{
                                            //    label: 'GaliboInt', // Etiqueta del conjunto de datos
                                            //    data: [
                                            //        { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresIntB[0], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresIntH[0] },
                                            //        { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresIntB[1], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresIntH[1] },
                                            //        { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresIntB[2], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresIntH[2] },
                                            //        { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresIntB[3], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresIntH[3] },
                                            //        { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresIntB[4], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresIntH[4] },
                                            //        { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresIntB[5], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresIntH[5] },
                                            //        { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresIntB[6], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresIntH[6] },
                                            //        { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresIntB[7], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresIntH[7] },
                                            //        { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresIntB[8], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresIntH[8] }
                                            //    ],
                                            //    borderColor: 'rgba(75, 192, 192, 1)', // Color de la línea Verde
                                            //    borderWidth: 2,
                                            //    showLine: true, // Muestra la línea que une los puntos
                                            //    fill: false, // Evita que se rellene el área debajo de la línea
                                            //    //tension: 0.4, // Suavizado de la línea
                                            //},
                                            //{
                                            //    label: 'GalibosExt', // Etiqueta del conjunto de datos
                                            //    data: [
                                            //        { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresExtB[0], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresExtH[0] },
                                            //        { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresExtB[1], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresExtH[1] },
                                            //        { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresExtB[2], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresExtH[2] },
                                            //        { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresExtB[3], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresExtH[3] },
                                            //        { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresExtB[4], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresExtH[4] },
                                            //        { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresExtB[5], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresExtH[5] },
                                            //        { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresExtB[6], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresExtH[6] },
                                            //        { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresExtB[7], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresExtH[7] },
                                            //        { x: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresExtB[8], y: $scope.galiboVerticalHorizontal.galibo.listaGaliboVectoresExtH[8] }
                                            //    ],
                                            //    borderColor: 'rgba(153, 102, 255, 1)', // Color de la línea Púrpura
                                            //    borderWidth: 2,
                                            //    showLine: true, // Muestra la línea que une los puntos
                                            //    fill: false, // Evita que se rellene el área debajo de la línea
                                            //    //tension: 0.4, // Suavizado de la línea
                                            //},
                                            {
                                                label: 'VmaxIntBmax', // Etiqueta del conjunto de datos
                                                data: [
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntBmaxB[0], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntBmaxH[0] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntBmaxB[1], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntBmaxH[1] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntBmaxB[2], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntBmaxH[2] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntBmaxB[3], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntBmaxH[3] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntBmaxB[4], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntBmaxH[4] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntBmaxB[5], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntBmaxH[5] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntBmaxB[6], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntBmaxH[6] }
                                                ],
                                                borderColor: 'rgba(255, 159, 64, 1)', // Color de la línea Naranja
                                                borderWidth: 2,
                                                showLine: true, // Muestra la línea que une los puntos
                                                fill: false, // Evita que se rellene el área debajo de la línea
                                                //tension: 0.4, // Suavizado de la línea
                                            },
                                            {
                                                label: 'VmaxIntHmax', // Etiqueta del conjunto de datos
                                                data: [
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntHmaxB[0], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntHmaxH[0] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntHmaxB[1], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntHmaxH[1] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntHmaxB[2], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntHmaxH[2] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntHmaxB[3], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntHmaxH[3] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntHmaxB[4], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntHmaxH[4] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntHmaxB[5], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntHmaxH[5] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntHmaxB[6], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxIntHmaxH[6] }
                                                ],
                                                borderColor: 'rgba(255, 206, 86, 1)', // Color de la línea Amarillo
                                                borderWidth: 2,
                                                showLine: true, // Muestra la línea que une los puntos
                                                fill: false, // Evita que se rellene el área debajo de la línea
                                                //tension: 0.4, // Suavizado de la línea
                                            },
                                            {
                                                label: 'VmaxExtBmax', // Etiqueta del conjunto de datos
                                                data: [
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtBmaxB[0], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtBmaxH[0] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtBmaxB[1], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtBmaxH[1] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtBmaxB[2], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtBmaxH[2] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtBmaxB[3], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtBmaxH[3] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtBmaxB[4], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtBmaxH[4] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtBmaxB[5], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtBmaxH[5] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtBmaxB[6], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtBmaxH[6] }
                                                ],
                                                borderColor: 'rgba(75, 192, 192, 1)', // Color de la línea Verde Claro
                                                borderWidth: 2,
                                                showLine: true, // Muestra la línea que une los puntos
                                                fill: false, // Evita que se rellene el área debajo de la línea
                                                //tension: 0.4, // Suavizado de la línea
                                            },
                                            {
                                                label: 'VmaxExtHmax', // Etiqueta del conjunto de datos
                                                data: [
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtHmaxB[0], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtHmaxH[0] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtHmaxB[1], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtHmaxH[1] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtHmaxB[2], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtHmaxH[2] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtHmaxB[3], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtHmaxH[3] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtHmaxB[4], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtHmaxH[4] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtHmaxB[5], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtHmaxH[5] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtHmaxB[6], y: $scope.galiboVerticalHorizontal.galibo.listaVmaxExtHmaxH[6] }
                                                ],
                                                borderColor: 'rgba(201, 203, 207, 1)', // Color de la línea Gris
                                                borderWidth: 2,
                                                showLine: true, // Muestra la línea que une los puntos
                                                fill: false, // Evita que se rellene el área debajo de la línea
                                                //tension: 0.4, // Suavizado de la línea
                                            },
                                            {
                                                label: 'V0IntBmax', // Etiqueta del conjunto de datos
                                                data: [
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0IntBmaxB[0], y: $scope.galiboVerticalHorizontal.galibo.listaV0IntBmaxH[0] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0IntBmaxB[1], y: $scope.galiboVerticalHorizontal.galibo.listaV0IntBmaxH[1] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0IntBmaxB[2], y: $scope.galiboVerticalHorizontal.galibo.listaV0IntBmaxH[2] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0IntBmaxB[3], y: $scope.galiboVerticalHorizontal.galibo.listaV0IntBmaxH[3] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0IntBmaxB[4], y: $scope.galiboVerticalHorizontal.galibo.listaV0IntBmaxH[4] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0IntBmaxB[5], y: $scope.galiboVerticalHorizontal.galibo.listaV0IntBmaxH[5] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0IntBmaxB[6], y: $scope.galiboVerticalHorizontal.galibo.listaV0IntBmaxH[6] }
                                                ],
                                                borderColor: 'rgba(255, 99, 71, 1)', // Color de la línea Tomate (Rojo claro)
                                                borderWidth: 2,
                                                showLine: true, // Muestra la línea que une los puntos
                                                fill: false, // Evita que se rellene el área debajo de la línea
                                                //tension: 0.4, // Suavizado de la línea
                                            },
                                            {
                                                label: 'V0IntHmax', // Etiqueta del conjunto de datos
                                                data: [
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0IntHmaxB[0], y: $scope.galiboVerticalHorizontal.galibo.listaV0IntHmaxH[0] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0IntHmaxB[1], y: $scope.galiboVerticalHorizontal.galibo.listaV0IntHmaxH[1] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0IntHmaxB[2], y: $scope.galiboVerticalHorizontal.galibo.listaV0IntHmaxH[2] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0IntHmaxB[3], y: $scope.galiboVerticalHorizontal.galibo.listaV0IntHmaxH[3] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0IntHmaxB[4], y: $scope.galiboVerticalHorizontal.galibo.listaV0IntHmaxH[4] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0IntHmaxB[5], y: $scope.galiboVerticalHorizontal.galibo.listaV0IntHmaxH[5] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0IntHmaxB[6], y: $scope.galiboVerticalHorizontal.galibo.listaV0IntHmaxH[6] }
                                                ],
                                                borderColor: 'rgba(30, 144, 255, 1)', // Color de la línea Azul Dodger
                                                borderWidth: 2,
                                                showLine: true, // Muestra la línea que une los puntos
                                                fill: false, // Evita que se rellene el área debajo de la línea
                                                //tension: 0.4, // Suavizado de la línea
                                            },
                                            {
                                                label: 'V0ExtBmax', // Etiqueta del conjunto de datos
                                                data: [
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0ExtBmaxB[0], y: $scope.galiboVerticalHorizontal.galibo.listaV0ExtBmaxH[0] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0ExtBmaxB[1], y: $scope.galiboVerticalHorizontal.galibo.listaV0ExtBmaxH[1] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0ExtBmaxB[2], y: $scope.galiboVerticalHorizontal.galibo.listaV0ExtBmaxH[2] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0ExtBmaxB[3], y: $scope.galiboVerticalHorizontal.galibo.listaV0ExtBmaxH[3] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0ExtBmaxB[4], y: $scope.galiboVerticalHorizontal.galibo.listaV0ExtBmaxH[4] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0ExtBmaxB[5], y: $scope.galiboVerticalHorizontal.galibo.listaV0ExtBmaxH[5] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0ExtBmaxB[6], y: $scope.galiboVerticalHorizontal.galibo.listaV0ExtBmaxH[6] }
                                                ],
                                                borderColor: 'rgba(60, 179, 113, 1)', // Color de la línea Verde marino medio
                                                borderWidth: 2,
                                                showLine: true, // Muestra la línea que une los puntos
                                                fill: false, // Evita que se rellene el área debajo de la línea
                                                //tension: 0.4, // Suavizado de la línea
                                            },
                                            {
                                                label: 'V0ExtHmax', // Etiqueta del conjunto de datos
                                                data: [
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0ExtHmaxB[0], y: $scope.galiboVerticalHorizontal.galibo.listaV0ExtHmaxH[0] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0ExtHmaxB[1], y: $scope.galiboVerticalHorizontal.galibo.listaV0ExtHmaxH[1] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0ExtHmaxB[2], y: $scope.galiboVerticalHorizontal.galibo.listaV0ExtHmaxH[2] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0ExtHmaxB[3], y: $scope.galiboVerticalHorizontal.galibo.listaV0ExtHmaxH[3] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0ExtHmaxB[4], y: $scope.galiboVerticalHorizontal.galibo.listaV0ExtHmaxH[4] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0ExtHmaxB[5], y: $scope.galiboVerticalHorizontal.galibo.listaV0ExtHmaxH[5] },
                                                    { x: $scope.galiboVerticalHorizontal.galibo.listaV0ExtHmaxB[6], y: $scope.galiboVerticalHorizontal.galibo.listaV0ExtHmaxH[6] }
                                                ],
                                                borderColor: 'rgba(138, 43, 226, 1)', // Color de la línea Azul Violeta
                                                borderWidth: 2,
                                                showLine: true, // Muestra la línea que une los puntos
                                                fill: false, // Evita que se rellene el área debajo de la línea
                                                //tension: 0.4, // Suavizado de la línea
                                            }
                                            ]
                                        },
                                        options: {
                                            scales: {
                                                x: {
                                                    type: 'linear',
                                                    position: 'bottom',
                                                    title: {
                                                        display: true,
                                                        text: 'X Axis'
                                                    }
                                                },
                                                y: {
                                                    title: {
                                                        display: true,
                                                        text: 'Y Axis'
                                                    }
                                                }
                                            }
                                        }
                                    });

                                    //Dibujo Grafico Nominal
                                    if (myChart2) {
                                        myChart2.destroy();
                                    }

                                    const ctx2 = document.getElementById('graficoNominal').getContext('2d');
                                    myChart2 = new Chart(ctx2, {
                                        type: 'scatter', // Tipo de gráfico
                                        data: {
                                            datasets: [//{
                                                //    label: 'GaliboEnvolventeInt', // Etiqueta del conjunto de datos
                                                //    data: [
                                                //        { x: $scope.galiboNominal.listaNomIntB[0], y: $scope.galiboNominal.listaNomIntH[0] },
                                                //        { x: $scope.galiboNominal.listaNomIntB[1], y: $scope.galiboNominal.listaNomIntH[1] },
                                                //        { x: $scope.galiboNominal.listaNomIntB[2], y: $scope.galiboNominal.listaNomIntH[2] },
                                                //        { x: $scope.galiboNominal.listaNomIntB[3], y: $scope.galiboNominal.listaNomIntH[3] },
                                                //        { x: $scope.galiboNominal.listaNomIntB[4], y: $scope.galiboNominal.listaNomIntH[4] },
                                                //        { x: $scope.galiboNominal.listaNomIntB[5], y: $scope.galiboNominal.listaNomIntH[5] },
                                                //        { x: $scope.galiboNominal.listaNomIntB[6], y: $scope.galiboNominal.listaNomIntH[6] },
                                                //        { x: $scope.galiboNominal.listaNomIntB[7], y: $scope.galiboNominal.listaNomIntH[7] },
                                                //        { x: $scope.galiboNominal.listaNomIntB[8], y: $scope.galiboNominal.listaNomIntH[8] }
                                                //    ],
                                                //    borderColor: 'rgba(75, 192, 192, 1)', // Color de la línea Verde
                                                //    borderWidth: 2,
                                                //    showLine: true, // Muestra la línea que une los puntos
                                                //    fill: false, // Evita que se rellene el área debajo de la línea
                                                //    //tension: 0.4, // Suavizado de la línea
                                                //},
                                                //{
                                                //    label: 'GaliboEnvolventeExt', // Etiqueta del conjunto de datos
                                                //    data: [
                                                //        { x: $scope.galiboNominal.listaNomExtB[0], y: $scope.galiboNominal.listaNomExtH[0] },
                                                //        { x: $scope.galiboNominal.listaNomExtB[1], y: $scope.galiboNominal.listaNomExtH[1] },
                                                //        { x: $scope.galiboNominal.listaNomExtB[2], y: $scope.galiboNominal.listaNomExtH[2] },
                                                //        { x: $scope.galiboNominal.listaNomExtB[3], y: $scope.galiboNominal.listaNomExtH[3] },
                                                //        { x: $scope.galiboNominal.listaNomExtB[4], y: $scope.galiboNominal.listaNomExtH[4] },
                                                //        { x: $scope.galiboNominal.listaNomExtB[5], y: $scope.galiboNominal.listaNomExtH[5] },
                                                //        { x: $scope.galiboNominal.listaNomExtB[6], y: $scope.galiboNominal.listaNomExtH[6] },
                                                //        { x: $scope.galiboNominal.listaNomExtB[7], y: $scope.galiboNominal.listaNomExtH[7] },
                                                //        { x: $scope.galiboNominal.listaNomExtB[8], y: $scope.galiboNominal.listaNomExtH[8] }
                                                //    ],
                                                //    borderColor: 'rgba(153, 102, 255, 1)', // Color de la línea Púrpura
                                                //    borderWidth: 2,
                                                //    showLine: true, // Muestra la línea que une los puntos
                                                //    fill: false, // Evita que se rellene el área debajo de la línea
                                                //    //tension: 0.4, // Suavizado de la línea
                                                //},
                                                {
                                                    label: 'GaliboInt', // Etiqueta del conjunto de datos
                                                    data: [
                                                        { x: $scope.galiboNominal.listaIntB[0], y: $scope.galiboNominal.listaIntH[0] },
                                                        { x: $scope.galiboNominal.listaIntB[1], y: $scope.galiboNominal.listaIntH[1] },
                                                        { x: $scope.galiboNominal.listaIntB[2], y: $scope.galiboNominal.listaIntH[2] },
                                                        { x: $scope.galiboNominal.listaIntB[3], y: $scope.galiboNominal.listaIntH[3] },
                                                        { x: $scope.galiboNominal.listaIntB[4], y: $scope.galiboNominal.listaIntH[4] },
                                                        { x: $scope.galiboNominal.listaIntB[5], y: $scope.galiboNominal.listaIntH[5] },
                                                        { x: $scope.galiboNominal.listaIntB[6], y: $scope.galiboNominal.listaIntH[6] },
                                                        { x: $scope.galiboNominal.listaIntB[7], y: $scope.galiboNominal.listaIntH[7] },
                                                        { x: $scope.galiboNominal.listaIntB[8], y: $scope.galiboNominal.listaIntH[8] }
                                                    ],
                                                    borderColor: 'rgba(255, 99, 132, 1)', // Color de la línea Rojo
                                                    borderWidth: 2,
                                                    showLine: true, // Muestra la línea que une los puntos
                                                    fill: false, // Evita que se rellene el área debajo de la línea
                                                    //tension: 0.4, // Suavizado de la línea
                                                },
                                                {
                                                    label: 'GalibosExt', // Etiqueta del conjunto de datos
                                                    data: [
                                                        { x: $scope.galiboNominal.listaExtB[0], y: $scope.galiboNominal.listaExtH[0] },
                                                        { x: $scope.galiboNominal.listaExtB[1], y: $scope.galiboNominal.listaExtH[1] },
                                                        { x: $scope.galiboNominal.listaExtB[2], y: $scope.galiboNominal.listaExtH[2] },
                                                        { x: $scope.galiboNominal.listaExtB[3], y: $scope.galiboNominal.listaExtH[3] },
                                                        { x: $scope.galiboNominal.listaExtB[4], y: $scope.galiboNominal.listaExtH[4] },
                                                        { x: $scope.galiboNominal.listaExtB[5], y: $scope.galiboNominal.listaExtH[5] },
                                                        { x: $scope.galiboNominal.listaExtB[6], y: $scope.galiboNominal.listaExtH[6] },
                                                        { x: $scope.galiboNominal.listaExtB[7], y: $scope.galiboNominal.listaExtH[7] },
                                                        { x: $scope.galiboNominal.listaExtB[8], y: $scope.galiboNominal.listaExtH[8] }
                                                    ],
                                                    borderColor: 'rgba(54, 162, 235, 1)', // Color de la línea Azul
                                                    borderWidth: 2,
                                                    showLine: true, // Muestra la línea que une los puntos
                                                    fill: false, // Evita que se rellene el área debajo de la línea
                                                    //tension: 0.4, // Suavizado de la línea
                                                },
                                                {
                                                    label: 'VmaxIntBmax', // Etiqueta del conjunto de datos
                                                    data: [
                                                        { x: $scope.galiboNominal.listaVmaxIntBmaxB[0], y: $scope.galiboNominal.listaVmaxIntBmaxH[0] },
                                                        { x: $scope.galiboNominal.listaVmaxIntBmaxB[1], y: $scope.galiboNominal.listaVmaxIntBmaxH[1] },
                                                        { x: $scope.galiboNominal.listaVmaxIntBmaxB[2], y: $scope.galiboNominal.listaVmaxIntBmaxH[2] },
                                                        { x: $scope.galiboNominal.listaVmaxIntBmaxB[3], y: $scope.galiboNominal.listaVmaxIntBmaxH[3] },
                                                        { x: $scope.galiboNominal.listaVmaxIntBmaxB[4], y: $scope.galiboNominal.listaVmaxIntBmaxH[4] },
                                                        { x: $scope.galiboNominal.listaVmaxIntBmaxB[5], y: $scope.galiboNominal.listaVmaxIntBmaxH[5] },
                                                        { x: $scope.galiboNominal.listaVmaxIntBmaxB[6], y: $scope.galiboNominal.listaVmaxIntBmaxH[6] }
                                                    ],
                                                    borderColor: 'rgba(255, 159, 64, 1)', // Color de la línea Naranja
                                                    borderWidth: 2,
                                                    showLine: true, // Muestra la línea que une los puntos
                                                    fill: false, // Evita que se rellene el área debajo de la línea
                                                    //tension: 0.4, // Suavizado de la línea
                                                },
                                                {
                                                    label: 'VmaxIntHmax', // Etiqueta del conjunto de datos
                                                    data: [
                                                        { x: $scope.galiboNominal.listaVmaxIntHmaxB[0], y: $scope.galiboNominal.listaVmaxIntHmaxH[0] },
                                                        { x: $scope.galiboNominal.listaVmaxIntHmaxB[1], y: $scope.galiboNominal.listaVmaxIntHmaxH[1] },
                                                        { x: $scope.galiboNominal.listaVmaxIntHmaxB[2], y: $scope.galiboNominal.listaVmaxIntHmaxH[2] },
                                                        { x: $scope.galiboNominal.listaVmaxIntHmaxB[3], y: $scope.galiboNominal.listaVmaxIntHmaxH[3] },
                                                        { x: $scope.galiboNominal.listaVmaxIntHmaxB[4], y: $scope.galiboNominal.listaVmaxIntHmaxH[4] },
                                                        { x: $scope.galiboNominal.listaVmaxIntHmaxB[5], y: $scope.galiboNominal.listaVmaxIntHmaxH[5] },
                                                        { x: $scope.galiboNominal.listaVmaxIntHmaxB[6], y: $scope.galiboNominal.listaVmaxIntHmaxH[6] }
                                                    ],
                                                    borderColor: 'rgba(255, 206, 86, 1)', // Color de la línea Amarillo
                                                    borderWidth: 2,
                                                    showLine: true, // Muestra la línea que une los puntos
                                                    fill: false, // Evita que se rellene el área debajo de la línea
                                                    //tension: 0.4, // Suavizado de la línea
                                                },
                                                {
                                                    label: 'VmaxExtBmax', // Etiqueta del conjunto de datos
                                                    data: [
                                                        { x: $scope.galiboNominal.listaVmaxExtBmaxB[0], y: $scope.galiboNominal.listaVmaxExtBmaxH[0] },
                                                        { x: $scope.galiboNominal.listaVmaxExtBmaxB[1], y: $scope.galiboNominal.listaVmaxExtBmaxH[1] },
                                                        { x: $scope.galiboNominal.listaVmaxExtBmaxB[2], y: $scope.galiboNominal.listaVmaxExtBmaxH[2] },
                                                        { x: $scope.galiboNominal.listaVmaxExtBmaxB[3], y: $scope.galiboNominal.listaVmaxExtBmaxH[3] },
                                                        { x: $scope.galiboNominal.listaVmaxExtBmaxB[4], y: $scope.galiboNominal.listaVmaxExtBmaxH[4] },
                                                        { x: $scope.galiboNominal.listaVmaxExtBmaxB[5], y: $scope.galiboNominal.listaVmaxExtBmaxH[5] },
                                                        { x: $scope.galiboNominal.listaVmaxExtBmaxB[6], y: $scope.galiboNominal.listaVmaxExtBmaxH[6] }
                                                    ],
                                                    borderColor: 'rgba(75, 192, 192, 1)', // Color de la línea Verde Claro
                                                    borderWidth: 2,
                                                    showLine: true, // Muestra la línea que une los puntos
                                                    fill: false, // Evita que se rellene el área debajo de la línea
                                                    //tension: 0.4, // Suavizado de la línea
                                                },
                                                {
                                                    label: 'VmaxExtHmax', // Etiqueta del conjunto de datos
                                                    data: [
                                                        { x: $scope.galiboNominal.listaVmaxExtHmaxB[0], y: $scope.galiboNominal.listaVmaxExtHmaxH[0] },
                                                        { x: $scope.galiboNominal.listaVmaxExtHmaxB[1], y: $scope.galiboNominal.listaVmaxExtHmaxH[1] },
                                                        { x: $scope.galiboNominal.listaVmaxExtHmaxB[2], y: $scope.galiboNominal.listaVmaxExtHmaxH[2] },
                                                        { x: $scope.galiboNominal.listaVmaxExtHmaxB[3], y: $scope.galiboNominal.listaVmaxExtHmaxH[3] },
                                                        { x: $scope.galiboNominal.listaVmaxExtHmaxB[4], y: $scope.galiboNominal.listaVmaxExtHmaxH[4] },
                                                        { x: $scope.galiboNominal.listaVmaxExtHmaxB[5], y: $scope.galiboNominal.listaVmaxExtHmaxH[5] },
                                                        { x: $scope.galiboNominal.listaVmaxExtHmaxB[6], y: $scope.galiboNominal.listaVmaxExtHmaxH[6] }
                                                    ],
                                                    borderColor: 'rgba(201, 203, 207, 1)', // Color de la línea Gris
                                                    borderWidth: 2,
                                                    showLine: true, // Muestra la línea que une los puntos
                                                    fill: false, // Evita que se rellene el área debajo de la línea
                                                    //tension: 0.4, // Suavizado de la línea
                                                },
                                                {
                                                    label: 'V0IntBmax', // Etiqueta del conjunto de datos
                                                    data: [
                                                        { x: $scope.galiboNominal.listaV0IntBmaxB[0], y: $scope.galiboNominal.listaV0IntBmaxH[0] },
                                                        { x: $scope.galiboNominal.listaV0IntBmaxB[1], y: $scope.galiboNominal.listaV0IntBmaxH[1] },
                                                        { x: $scope.galiboNominal.listaV0IntBmaxB[2], y: $scope.galiboNominal.listaV0IntBmaxH[2] },
                                                        { x: $scope.galiboNominal.listaV0IntBmaxB[3], y: $scope.galiboNominal.listaV0IntBmaxH[3] },
                                                        { x: $scope.galiboNominal.listaV0IntBmaxB[4], y: $scope.galiboNominal.listaV0IntBmaxH[4] },
                                                        { x: $scope.galiboNominal.listaV0IntBmaxB[5], y: $scope.galiboNominal.listaV0IntBmaxH[5] },
                                                        { x: $scope.galiboNominal.listaV0IntBmaxB[6], y: $scope.galiboNominal.listaV0IntBmaxH[6] }
                                                    ],
                                                    borderColor: 'rgba(255, 99, 71, 1)', // Color de la línea Tomate (Rojo claro)
                                                    borderWidth: 2,
                                                    showLine: true, // Muestra la línea que une los puntos
                                                    fill: false, // Evita que se rellene el área debajo de la línea
                                                    //tension: 0.4, // Suavizado de la línea
                                                },
                                                {
                                                    label: 'V0IntHmax', // Etiqueta del conjunto de datos
                                                    data: [
                                                        { x: $scope.galiboNominal.listaV0IntHmaxB[0], y: $scope.galiboNominal.listaV0IntHmaxH[0] },
                                                        { x: $scope.galiboNominal.listaV0IntHmaxB[1], y: $scope.galiboNominal.listaV0IntHmaxH[1] },
                                                        { x: $scope.galiboNominal.listaV0IntHmaxB[2], y: $scope.galiboNominal.listaV0IntHmaxH[2] },
                                                        { x: $scope.galiboNominal.listaV0IntHmaxB[3], y: $scope.galiboNominal.listaV0IntHmaxH[3] },
                                                        { x: $scope.galiboNominal.listaV0IntHmaxB[4], y: $scope.galiboNominal.listaV0IntHmaxH[4] },
                                                        { x: $scope.galiboNominal.listaV0IntHmaxB[5], y: $scope.galiboNominal.listaV0IntHmaxH[5] },
                                                        { x: $scope.galiboNominal.listaV0IntHmaxB[6], y: $scope.galiboNominal.listaV0IntHmaxH[6] }
                                                    ],
                                                    borderColor: 'rgba(30, 144, 255, 1)', // Color de la línea Azul Dodger
                                                    borderWidth: 2,
                                                    showLine: true, // Muestra la línea que une los puntos
                                                    fill: false, // Evita que se rellene el área debajo de la línea
                                                    //tension: 0.4, // Suavizado de la línea
                                                },
                                                {
                                                    label: 'V0ExtBmax', // Etiqueta del conjunto de datos
                                                    data: [
                                                        { x: $scope.galiboNominal.listaV0ExtBmaxB[0], y: $scope.galiboNominal.listaV0ExtBmaxH[0] },
                                                        { x: $scope.galiboNominal.listaV0ExtBmaxB[1], y: $scope.galiboNominal.listaV0ExtBmaxH[1] },
                                                        { x: $scope.galiboNominal.listaV0ExtBmaxB[2], y: $scope.galiboNominal.listaV0ExtBmaxH[2] },
                                                        { x: $scope.galiboNominal.listaV0ExtBmaxB[3], y: $scope.galiboNominal.listaV0ExtBmaxH[3] },
                                                        { x: $scope.galiboNominal.listaV0ExtBmaxB[4], y: $scope.galiboNominal.listaV0ExtBmaxH[4] },
                                                        { x: $scope.galiboNominal.listaV0ExtBmaxB[5], y: $scope.galiboNominal.listaV0ExtBmaxH[5] },
                                                        { x: $scope.galiboNominal.listaV0ExtBmaxB[6], y: $scope.galiboNominal.listaV0ExtBmaxH[6] }
                                                    ],
                                                    borderColor: 'rgba(60, 179, 113, 1)', // Color de la línea Verde marino medio
                                                    borderWidth: 2,
                                                    showLine: true, // Muestra la línea que une los puntos
                                                    fill: false, // Evita que se rellene el área debajo de la línea
                                                    //tension: 0.4, // Suavizado de la línea
                                                },
                                                {
                                                    label: 'V0ExtHmax', // Etiqueta del conjunto de datos
                                                    data: [
                                                        { x: $scope.galiboNominal.listaV0ExtHmaxB[0], y: $scope.galiboNominal.listaV0ExtHmaxH[0] },
                                                        { x: $scope.galiboNominal.listaV0ExtHmaxB[1], y: $scope.galiboNominal.listaV0ExtHmaxH[1] },
                                                        { x: $scope.galiboNominal.listaV0ExtHmaxB[2], y: $scope.galiboNominal.listaV0ExtHmaxH[2] },
                                                        { x: $scope.galiboNominal.listaV0ExtHmaxB[3], y: $scope.galiboNominal.listaV0ExtHmaxH[3] },
                                                        { x: $scope.galiboNominal.listaV0ExtHmaxB[4], y: $scope.galiboNominal.listaV0ExtHmaxH[4] },
                                                        { x: $scope.galiboNominal.listaV0ExtHmaxB[5], y: $scope.galiboNominal.listaV0ExtHmaxH[5] },
                                                        { x: $scope.galiboNominal.listaV0ExtHmaxB[6], y: $scope.galiboNominal.listaV0ExtHmaxH[6] }
                                                    ],
                                                    borderColor: 'rgba(138, 43, 226, 1)', // Color de la línea Azul Violeta
                                                    borderWidth: 2,
                                                    showLine: true, // Muestra la línea que une los puntos
                                                    fill: false, // Evita que se rellene el área debajo de la línea
                                                    //tension: 0.4, // Suavizado de la línea
                                                }
                                            ]
                                        },
                                        options: {
                                            scales: {
                                                x: {
                                                    type: 'linear',
                                                    position: 'bottom',
                                                    title: {
                                                        display: true,
                                                        text: 'X Axis'
                                                    }
                                                },
                                                y: {
                                                    title: {
                                                        display: true,
                                                        text: 'Y Axis'
                                                    }
                                                }
                                            }
                                        }
                                    });
                                });

                            //alert('Cálculo de gálibo andén completado')
                        });

                    //alert('Cálculo de desplazamientos laterales verticales completado')
                    $scope.descargaActiva = false;
                    elemento.style.display = 'none';
                });
        }


        galibosService.getContornosInferiores()
            .then(function (response) {
                var data = response.data;
                $scope.contornosinferiores = data;
            });

        galibosService.getAnchosVia()
            .then(function (response) {
                var data = response.data;
                $scope.anchosvia = data;
            });

        galibosService.getPantografo()
            .then(function (response) {
                var data = response.data;
                $scope.pantografos = data;
            });

        galibosService.getTension()
            .then(function (response) {
                var data = response.data;
                $scope.tensiones = data;
            });

        galibosService.getEstadoBalasto()
            .then(function (response) {
                var data = response.data;
                $scope.estadosbalasto = data;
            });

        galibosService.getDatosParametrizables($scope.usuario)
            .then(function (response) {
                var data = response.data;
                //$scope.datosparametrizables = data;
                $scope.datosparametrizables = data;
            });

        galibosService.getRol($scope.usuario, $scope.InfraestructuraId)
            .then(function (response) {
                $scope.permiso = response.data;
            });

        $scope.pestanyavisible = 'datosentrada';

        $scope.indice = 0;


        $scope.volver = function () {
            $location.path('/infraestructura/' + $scope.InfraestructuraId);
            $rootScope.galibosUrl = true;
        }

        $scope.DatosEntradaCargar = function () {
            $scope.pestanyavisible = 'datosentrada';
        }

        $scope.DatosParametrizablesCargar = function () {
            $scope.pestanyavisible = 'datosparametrizables';
        }


        $scope.logout = function () {
            return authenticationService.logout();
        }


        $scope.limpiardatosentrada = function () {
            $scope.Contorno = null;
            $scope.AnchoVia = null;
            $scope.ContornoInferior = null;
            $scope.Pantografo = null;
            $scope.Tension = null;
            $scope.EstadoBalasto = null;
            $scope.R = null;
            $scope.D = null;
            $scope.I = null;
            $scope.TD = null;
            $scope.TN = null;
            $scope.Rv = null;
            $scope.Hcrmin = null;
            $scope.Hnez = null;
            $scope.Tq = null;
            $scope.tipoAnden = null;
            $scope.bordeInt = null;
            $scope.bordeExt = null;
            $scope.catenaria = null;
        }



        $scope.limpiardatosparametrizables = function () {
            $scope.datosparametrizables.lmax = null;
            $scope.datosparametrizables.L = null;
            $scope.datosparametrizables.D0 = null;
            $scope.datosparametrizables.I0 = null;
            $scope.datosparametrizables.hc0 = null;
            $scope.datosparametrizables.Tvia = null;
            $scope.datosparametrizables.ac = null;
            $scope.datosparametrizables.asusp = null;
            $scope.datosparametrizables.Kgen = null;
            $scope.datosparametrizables.Kh05 = null;
            $scope.datosparametrizables.M3balto = null;
            $scope.datosparametrizables.M3bbajo = null;
            $scope.datosparametrizables.M3halto = null;
            $scope.datosparametrizables.M3hbajo = null;
            $scope.datosparametrizables.pant_ho = null;
            $scope.datosparametrizables.pant_hu = null;
            $scope.datosparametrizables.pant_epo = null;
            $scope.datosparametrizables.pant_epu = null;
            $scope.datosparametrizables.pant_I0 = null;
            $scope.datosparametrizables.pant_Do = null;
            $scope.datosparametrizables.pant_cw = null;
            $scope.datosparametrizables.pant_hf = null;
        }



        $scope.descargarDXF = function () {

            var elemento = document.getElementById('loader-seguimiento');
            elemento.style.display = 'block';

            var formDataTexto = new FormData();
            formDataTexto.append("galiboVerticalHorizontal", JSON.stringify($scope.galiboVerticalHorizontal));
            formDataTexto.append("galiboNominal", JSON.stringify($scope.galiboNominal));
            formDataTexto.append("galiboAnden", JSON.stringify($scope.galiboAnden));

            galibosService.dibujarGraficos(formDataTexto)
                .then(function (response) {

                    var filename = "Grafico.zip";
                    var contentType = 'application/zip';

                    var blob = new Blob([response.data],
                        { type: contentType });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                        window.navigator.msSaveBlob(blob, filename);
                    else //Resto
                    {
                        var downloadLink = document.createElement("a");

                        var url = window.URL.createObjectURL(blob);
                        downloadLink.setAttribute("target", "_blank");
                        downloadLink.setAttribute("href", url);
                        downloadLink.setAttribute("download", filename);
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                        elemento.style.display = 'none';
                    }
                    //alert('Graficos Dibujados')
                })
        }


    }
]);